<template>
  <div class="covid19">
    <div id="cov-19-canvas" style="width: 100%"></div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Konva from 'konva'
export default {
  name: 'covid19',
  data () {
    return {
      covid: {}
    }
  },
  computed: {
    terkonfirmasi () {
      if (typeof this.covid.total !== 'undefined' && typeof this.covid.total.konfirmasi !== 'undefined') {
        return (this.covid.total.konfirmasi.masih_isolasi + this.covid.total.konfirmasi.meninggal + this.covid.total.konfirmasi.sembuh)
      }
      return 0
    }
  },
  methods: {
    windowOnLoad () {
      window.addEventListener('resize', this.windowOnResize)
      this.fitStageIntoParentContainer()
    },
    windowOnResize () {
      this.fitStageIntoParentContainer()
    },
    fitStageIntoParentContainer() {
        var container = document.querySelector('#cov-19-canvas');

        // now we need to fit stage into parent
        var containerWidth = container.offsetWidth;
        // to do this we need to scale the stage
        var scale = containerWidth / 600;

        this.banner.width(600 * scale);
        this.banner.height(500 * scale);
        this.banner.scale({ x: scale, y: scale });
        this.banner.draw();
    },
    addmoment (mdate) {
      return moment(mdate).locale('id').format("dddd, DD MMMM YYYY HH:mm")
    },
    numberFormat (number) {
      return new Intl.NumberFormat('id-ID').format(number)
    },
    async getInfo () {
      try {
        const info = await axios.get('https://corona.ntbprov.go.id/api/data')
        const data = info.data
        this.covid = data
        this.dataCovid()
        this.downloadButton()
      } catch (error) {
        console.log('error')
      }
    },
    dataCovid () {
      this.createTitle()
      const positif = new Konva.Text({
        text: this.numberFormat(this.terkonfirmasi),
        fontSize: 50,
        x: 20, y: 280,
        fontFamily: "Harabara",
        fill: '#005AAC',
        align: 'center',
        width: 180,
        opacity: 0.8
      })
      this.layer.add(positif)
      const sembuh = new Konva.Text({
        text: this.numberFormat(this.covid.total.konfirmasi.sembuh),
        fontSize: 50,
        x: 206, y: 280,
        fontFamily: "Harabara",
        fill: '#005AAC',
        align: 'center',
        width: 180,
        opacity: 0.8
      })
      this.layer.add(sembuh)
      const meninggal = new Konva.Text({
        text: this.numberFormat(this.covid.total.konfirmasi.meninggal),
        fontSize: 50,
        x: 400, y: 280,
        fontFamily: "Harabara",
        fill: '#005AAC',
        align: 'center',
        width: 180,
        opacity: 0.8
      })
      this.layer.add(meninggal)

      const cc = new Konva.Circle({
        x: 75, y: 74,
        radius: 7,
        fill: '#fc9803'
      })
      this.layer.add(cc)

      const updateDate = new Konva.Text({
        text: this.addmoment(this.covid.update_terakhir),
        fontSize: 10,
        x: 90, y: 70,
        fontFamily: "sans-serif",
        fill: '#ffffff'
      })
      this.layer.add(updateDate)
      this.layer.batchDraw()
    },
    createTitle () {
      const datakasus = new Konva.Text({
        text: 'DATA KASUS',
        fontSize: 50,
        x: 150, y: 105,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(datakasus)
      const covidNTB = new Konva.Text({
        text: 'COVID-19 NTB',
        fontSize: 35,
        x: 195, y: 150,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(covidNTB)
    },
    createBanner () {
      this.banner = new Konva.Stage({
        container: 'cov-19-canvas',
        width: 600,
        height: 500
      })

      this.layer = new Konva.Layer()
      this.banner.add(this.layer)
      const background = new Konva.Rect({
        x: 0, y: 0,
        height: 500, width: 650, fill: '#005AAC'
      })
      this.layer.add(background)
      const lines = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,50 H250',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines)

      const lines1 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,60 H270',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines1)

      const lines2 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,70 H260',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines2)

      const lines3 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,80 H270',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines3)

      const lines4 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,90 H250',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines4)

      const lines5 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,100 H220',
        stroke: '#acadad',
        shadowBlur: 20,
        opacity: 0.1
      })

      this.layer.add(lines5)

      const lines6 = new Konva.Path({
        x: 0, y: 20,
        fill: null,
        scale: { x:2, y:2 },
        data: 'M0,110 H240',
        stroke: '#acadad',
        shadowBlur: 20,
        shadowOffset: {x: 1, y: 2},
        opacity: 0.1
      })

      this.layer.add(lines6)


      const logoBg = new Konva.Rect({
        x: 400, y: 0,
        height: 70, width: 200, fill: '#FFFFFF',
        cornerRadius: [0,0,0,80]
      })
      this.layer.add(logoBg)
      const harianText = new Konva.Text({
        text: 'HarianNTB',
        fontSize: 34,
        x: 440, y: 28,
        fontFamily: "Harabara",
        fill: '#005AAC'
      })
      this.layer.add(harianText)

      const updateTxt = new Konva.Text({
        text: 'U P D A T E',
        fontSize: 40,
        x: 25, y: 28,
        fontFamily: "Harabara",
        fill: '#fc9803',
        shadowOffset: { x: 2, y: 1 },
        shadowOpacity: .5
      })
      this.layer.add(updateTxt)

      const comEx = new Konva.Rect({
        x: 550, y: 10,
        height: 16, width: 32, fill: '#fc9803',
        cornerRadius: [50,50,50,0]
      })
      this.layer.add(comEx)
      const comText = new Konva.Text({
        text: '.COM',
        fontSize: 12,
        x: 552, y: 13,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(comText)
      const ltrp = new Konva.Text({
        text: 'LITERASI PERUBAHAN',
        fontSize: 8,
        x: 440, y: 56,
        fontFamily: "sans-serif",
        fill: '#005AAC'
      })
      this.layer.add(ltrp)

      // sumber
      const sumber = new Konva.Text({
        text: 'sumber data: https://corona.ntbprov.go.id',
        fontSize: 12,
        x: 360, y: 390,
        fontFamily: "sans-serif",
        fill: '#FFFFFF'
      })
      this.layer.add(sumber)

      const domain = new Konva.Text({
        text: 'www.harianntb.com',
        fontSize: 18,
        x: 125, y: 470,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(domain)

      const divider = new Konva.Text({
        text: '|',
        fontSize: 18,
        x: 300, y: 470,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(divider)

      const ntblwcovid = new Konva.Text({
        text: '#NTBLawanCovid',
        fontSize: 18,
        x: 339, y: 470,
        fontFamily: "Harabara",
        fill: '#FFFFFF'
      })
      this.layer.add(ntblwcovid)



      const positif = new Konva.Rect({
        x: 20, y: 250,
        height: 130, width: 180, fill: '#FFFFFF',
        cornerRadius: 15,
        shadowColor: '#000',
        shadowOffset: {x: 5, y: 8},
        shadowOpacity: 0.3
      })
      this.layer.add(positif)
      const positifLabel = new Konva.Rect({
        x: 34, y: 340,
        height: 30, width: 150, fill: '#005AAC',
        cornerRadius: 10,
        opacity: 0.8
      })
      this.layer.add(positifLabel)
      const positifTextLabel = new Konva.Text({
        text: 'TERKONFIRMASI',
        fontSize: 13,
        x: 55, y: 350,
        fontFamily: "sans-serif",
        fill: '#FFFFFF'
      })
      this.layer.add(positifTextLabel)

      // sembuh
      const sembuh = new Konva.Rect({
        x: 210, y: 250,
        height: 130, width: 180, fill: '#FFFFFF',
        cornerRadius: 15,
        shadowColor: '#000',
        shadowOffset: {x: 5, y: 8},
        shadowOpacity: 0.3
      })
      this.layer.add(sembuh)
      const sembuhLabel = new Konva.Rect({
        x: 224, y: 340,
        height: 30, width: 150, fill: '#005AAC',
        cornerRadius: 10,
        opacity: 0.8
      })
      this.layer.add(sembuhLabel)
      const sembuhTextLabel = new Konva.Text({
        text: 'SEMBUH',
        fontSize: 13,
        x: 270, y: 350,
        fontFamily: "sans-serif",
        fill: '#FFFFFF'
      })
      this.layer.add(sembuhTextLabel)

      // meninggal
      const meninggal = new Konva.Rect({
        x: 400, y: 250,
        height: 130, width: 180, fill: '#FFFFFF',
        cornerRadius: 15,
        shadowColor: '#000',
        shadowOffset: {x: 5, y: 8},
        shadowOpacity: 0.3
      })
      this.layer.add(meninggal)
      const meninggalLabel = new Konva.Rect({
        x: 414, y: 340,
        height: 30, width: 150, fill: '#005AAC',
        cornerRadius: 10,
        opacity: 0.8
      })
      this.layer.add(meninggalLabel)
      const meninggalTextLabel = new Konva.Text({
        text: 'MENINGGAL',
        fontSize: 13,
        x: 450, y: 350,
        fontFamily: "sans-serif",
        fill: '#FFFFFF'
      })
      this.layer.add(meninggalTextLabel)
      this.layer.batchDraw()
      this.getInfo()
    },
    downloadButton () {
      this.layerDownload = new Konva.Layer()
      this.banner.add(this.layerDownload)

      const rect = new Konva.Rect({
        y: 461, x: 490, width: 100, height: 30,
        fill: "#fff",
        opacity: 0.1,
        cornerRadius: 5
      })
      const text = new Konva.Text({
        y: 468, x: 502,
        text: 'download',
        fill: '#FFFFFF',
        fontSize: 18,
        fontFamily: 'Harabara'
      })

      rect.on('click touchstart', this.sizeStage)
      text.on('click touchstart', this.sizeStage)

      this.layerDownload.add(rect)
      this.layerDownload.add(text)
      const link = () => {
        this.banner.container().style.cursor = 'pointer'
      }
      link.bind(this)
      rect.on('mouseenter', link)
      this.layerDownload.batchDraw()
    },
    donloadLink (uri, name) {
        var link = document.createElement('a')
        link.download = name
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.layerDownload.show()
    },
    sizeStage () {  
      this.layerDownload.hide()
      const date = moment(this.covid.update_terakhir).locale('id').format("dddd-DDMMMMYYYY")
      var dataURL = this.banner.toDataURL({ pixelRatio: 4 })
      this.donloadLink(dataURL, date + 'covidinfo-harianntb.png')
    }
  },
  mounted () {
    this.createBanner()
    this.$nextTick(this.windowOnLoad)
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&display=swap');
@font-face { 
       font-family: "Harabara";
       src: url("../assets/fonts/Harabara.ttf"); 
  }
</style>
