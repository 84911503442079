import Vue from 'vue'
//import Covid19 from './components/covid19.vue'
import COvidBanner from './components/CovidBanner.vue'

Vue.config.productionTip = false

Vue.component('covid-19', COvidBanner)

new Vue({
  el: '#wpapp'
})
